/*

──────────────────────────────────────────
──────────────────────────────────────────
MOUSEMOVE
──────────────────────────────────────────
──────────────────────────────────────────

►►►  el is optional → document element by default

S.BM(this, ['mousemove'])

this.mm = new RM.MM({cb: this.mousemove, el: '#element'})

this.mm.on()

mousemove (x, y, e) {
  console.log(x)
}

this.mm.off()

*/

var MM = function (o) {
  this.cb = o.cb
  this.el = RM.Has(o, 'el') ? (RM.Is.str(o.el) ? RM.Select.el(o.el)[0] : o.el) : document

  this.iM = RM.Snif.isMobile
  this.eT = this.iM ? 'touch' : 'mouse'
  this.tick = false

  RM.BM(this, ['gRaf', 'run'])

  this.raf = new RM.Raf(this.run)
}

MM.prototype = {

  on: function () {
    this.l('a')
  },

  off: function () {
    this.l('r')
  },

  l: function (a) {
    RM.L(this.el, a, this.eT + 'move', this.gRaf)
  },

  gRaf: function (e) {
    this.e = e
    // if (this.e.cancelable) {
    //   this.e.preventDefault()
    // }

    if (!this.tick) {
      this.tick = true
      this.raf.run()
    }
  },

  run: function () {
    var t = this.iM ? this.e.changedTouches[0] : this.e
    this.cb(t['pageX'], t['pageY'], this.e)

    this.raf.stop()
    this.tick = false
  }

}

RM.MM = MM