/*

──────────────────────────────────────────
──────────────────────────────────────────
DELAY
──────────────────────────────────────────
──────────────────────────────────────────

const delay = new RM.Delay(_ => {
  this.on()
}, 3500)

delay.run()
delay.stop()

*/

RM.Delay = function (cb, d) {
  this.cb = cb
  this.d = d

  RM.BM(this, ['loop'])

  this.raf = new RM.Raf(this.loop)
}

RM.Delay.prototype = {

  run: function () {
    if (this.d === 0) {
      this.cb()
    } else {
      this.raf.run()
    }
  },

  stop: function () {
    this.raf.stop()
  },

  loop: function (t) {
    var elapsed = RM.Clamp(t, 0, this.d)
    var prog = RM.Clamp(elapsed / this.d, 0, 1)

    if (prog === 1) {
      this.stop()
      this.cb()
    }
  }

}