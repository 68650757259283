/*
──────────────────────────────────────────
──────────────────────────────────────────
TRANSLATE
──────────────────────────────────────────
──────────────────────────────────────────
RM.T(element, translateX, translateY, unity)
►►►  unity → 'px' or '%' by default
*/

RM.T = function (a, b, c, d) {
  var e = RM.Is.und(d) ? '%' : d
  a.style.transform = 'translate3d(' + b + e + ',' + c + e + ',0)'
}