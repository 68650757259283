const settings = {
  brand_primary  : '#ffc308',
  brand_secondary: '#282828',
  gray_lighter   : '#e9ecef',
  gray_lightest  : '#f8f9fa',
  white          : '#fff',

  ease           : [.165,.84,.44,1],
  easeBezier     : 'cubicBezier(.165,.84,.44,1)',
  ease_in        : [.95,.05,.795,.035],
  ease_inBezier  : 'cubicBezier(.95,.05,.795,.035)',
  ease_in_out    : [.77,0,.175,1],
  ease_in_outBezier: 'cubicBezier(.77,0,.175,1)',

  transition     : 400,
  transition_fast: 250,
  transition_slow: 800,
  transition__inS     : .400,
  transition_fast__inS: .250,
  transition_slow__inS: .800,
}

export default settings