import prestashop from 'prestashop';
import $ from 'jquery';

$(document).ready(function(){"use strict";

  // Scroll back to top

  var $window   = $(window),
      $document = $(document),
      offset    = 50,
      scrollTop = 0,
      $progressWrap = $('.progress-wrap');

  var getHeight = function () {
    return $document.height() - $window.height();
  }
  var theHeight = getHeight();

  var progressPath = document.querySelector('.progress-wrap path');
  var pathLength = progressPath.getTotalLength();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
  progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
  progressPath.style.strokeDashoffset = pathLength;
  progressPath.getBoundingClientRect();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

  var updateProgress = function () {
    var progress = pathLength - (scrollTop * pathLength / theHeight);
    progressPath.style.strokeDashoffset = progress;
  }
  updateProgress();

  // $(window).scroll(updateProgress);
  $(window).scroll(throttle(updateProgress, 60));

  $window.on('scroll', function() {
    scrollTop = $(this).scrollTop();

    if (scrollTop > offset) {
      $progressWrap.addClass('active-progress');
    } else {
      $progressWrap.removeClass('active-progress');
    }
  });

  $window.on('resize', function() {
    theHeight = getHeight();
  });

  $window.on('load', function() {
    theHeight = getHeight();
  });

  $progressWrap.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, variables.transition_slow);
    return false;
  });

});