/*

──────────────────────────────────────────
──────────────────────────────────────────
ROUND
──────────────────────────────────────────
──────────────────────────────────────────

RM.R(number, precision)

►►►  precision is optional → 2 by default

0 → 1
1 → 0.1
2 → 0.01
3 → 0.001

*/

RM.R = function (n, p) {
  var p = RM.Is.und(p) ? 100 : Math.pow(10, p)
  return Math.round(n * p) / p
}
