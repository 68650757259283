/*

──────────────────────────────────────────
──────────────────────────────────────────
LISTENER
──────────────────────────────────────────
──────────────────────────────────────────

ADD
───

RM.L(element, 'a', 'click', callback)

REMOVE
──────

RM.L(element, 'r', 'click', callback)

EXAMPLE
───────

listen (action) {
  RM.L(element, action, 'click', callback)
}

this.listen('a')
this.listen('r')

PASSIVE EVENT SRC
─────────────────
►►►  on false to be able to use preventDefault
https://developers.google.com/web/tools/lighthouse/audits/passive-event-listeners
https://developers.google.com/web/updates/2016/06/passive-event-listeners
https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Parameters

*/

RM.L = function (el, a, t, cb) {
  var d = document
  var el = RM.Select.el(el)
  var elL = el.length
  var type = t
  var w = 'wheel'
  var m = 'move'
  var mo = 'mouse'
  var to = 'touch'
  var f = 'focus'
  var pE = [mo + 'Wheel', mo + m, to + m, to + 'start']
  var p = pE.indexOf(t) === -1 ? false : {passive: false}

  if (t === pE[0]) {
    type = 'on' + w in d ? w : RM.Is.def(d.onmousewheel) ? mo + w : 'DOMMouseScroll'
  } else if (t === f + 'Out') {
    type = RM.Snif.isFirefox ? 'blur' : f + 'out'
  }

  var action = a === 'a' ? 'add' : 'remove'
  for (var i = 0; i < elL; i++) {
    el[i][action + 'EventListener'](type, cb, p)
  }
}