/*

──────────────────────────────────────────
──────────────────────────────────────────
POINTER EVENT
──────────────────────────────────────────
──────────────────────────────────────────

RM.PE.all(element)
RM.PE.none(element)

*/

RM.PE = {
  s: function (a, b) {
    a.style.pointerEvents = b
  },

  all: function (a) {
    this.s(a, 'all')
  },

  none: function (a) {
    this.s(a, 'none')
  }
}