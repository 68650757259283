/*

──────────────────────────────────────────
──────────────────────────────────────────
SELECT
──────────────────────────────────────────
──────────────────────────────────────────

const el[0] = RM.Select.el(selector)
const type  = RM.Select.type(selector)
const name  = RM.Select.name(selector)

*/

RM.Select = {
  p: function (p) {
    return p ? p : document
  },

  el: function (s, p) {
    var el = []
    if (RM.Is.str(s)) {
      var elName = s.substring(1)
      if (s.charAt(0) === '#') {
        el[0] = RM.G.id(elName, p)
      } else {
        el = RM.G.class(elName, p)
      }
    } else {
      el[0] = s
    }
    return el
  },

  query: function (s, p) {
    var el = []
    if (RM.Is.str(s)) {
      el = this.p(p).querySelector(s)
    } else {
      el[0] = s
    }
    return el
  },

  queryAll: function (s, p) {
    var el = []
    if (RM.Is.str(s)) {
      el = this.p(p).querySelectorAll(s)
    } else {
      el[0] = s
    }
    return el
  },

  type: function (s) {
    return s.charAt(0) === '#' ? 'id' : 'class'
  },

  name: function (s) {
    return s.substring(1)
  }
}