const SELECTORS = {
  pointer: '_p'
}

export default class Pointer {

  constructor () {
    this.isRunning = false;
    RM.BM(this, ['onAppInit', 'onAppLoaded', 'onAppLoadedEnded']);
    this.timer = new RM.Delay(_ => {
      this.isRunning = false;
      this.pointer('all');
    }, 100);
  }

  init () {
    this.el  = RM.G.class(SELECTORS.pointer);
    this.elL = this.el.length;
  }

  run () {
    this.timer.stop();
    if ( !this.isRunning ) {
      this.isRunning = true;
      this.pointer('none');
    }
    this.timer.run();
  }

  pointer (state) {
    // debug('set pointerEvents to : ' + state)
    for (let i = 0; i< this.elL; i++) {
      if (RM.Has(RM.PE, state)) {
        RM.PE[state](this.el[i]);
      } else {
        this.el[i].style.pointerEvents = state;
      }
    }
  }

  onAppInit() {
    this.init();
  }

  onAppLoaded() {
  }

  onAppLoadedEnded() {
  }

  onAppResize() {
  }
}
