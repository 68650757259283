/*

──────────────────────────────────────────
──────────────────────────────────────────
RESIZE & ORIENTATION
──────────────────────────────────────────
──────────────────────────────────────────

S.BM(this, ['resize'])

this.ro = new RM.RO({cb: this.resize, throttleDelay: 100})

this.ro.on()

resize (event) {
  console.log(event)
}

this.ro.off()

*/

var RO = function (o) {
  this.cb = o.cb

  this.eT = RM.Snif.isMobile ? 'orientationchange' : 'resize'
  this.tick = false

  RM.BM(this, ['gT', 'gRaf', 'run'])

  this.t = new RM.Throttle({
    delay: o.throttleDelay,
    onlyAtEnd: true,
    cb: this.gRaf
  })
  this.raf = new RM.Raf(this.run)
}

RO.prototype = {

  on: function () {
    this.l('a')
  },

  off: function () {
    this.l('r')
  },

  l: function (a) {
    RM.L(window, a, this.eT, this.gT)
  },

  gT: function (e) {
    this.e = e
    this.t.run()
  },

  gRaf: function () {
    if (!this.tick) {
      this.tick = true
      this.raf.run()
    }
  },

  run: function () {
    this.cb(this.e)

    this.raf.stop()
    this.tick = false
  }

}

RM.RO = RO