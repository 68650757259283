/*

──────────────────────────────────────────
──────────────────────────────────────────
CLAMP
──────────────────────────────────────────
──────────────────────────────────────────

RM.Clamp(value, min, max)

*/

RM.Clamp = function (v, a, z) {
  return Math.min(Math.max(v, a), z)
}