/*

──────────────────────────────────────────
──────────────────────────────────────────
GET ELEMENT BY
──────────────────────────────────────────
──────────────────────────────────────────

const content = RM.G.id('content')
const btn     = RM.G.class('btn')
const span    = RM.G.tag('span')

CHILD OF ELEMENT
────────────────

const elements = RM.G.class('elementClassName', parentEl)

*/

RM.G = {
  p: function (p) {
    return p ? p : document
  },

  id: function (el) {
    return document.getElementById(el)
  },

  class: function (el, p) {
    return this.p(p).getElementsByClassName(el)
  },

  tag: function (el, p) {
    return this.p(p).getElementsByTagName(el)
  }
}